<template>
  <div>
    <!--begin::Employees-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Subscription</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <!-- <button
                type="button"
                class="ma-2 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectTo()">
                  Back
                </span>
              </button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            Your current plan is : <b>{{ subscription_type }}</b>
          </h3>
           <!-- start:Model -->
            <v-dialog
              v-model="dialog"
              persistent
              width="800"
              v-if="is_company_admin == 1"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="green lighten-2"
                  dark
                  v-on="on"
                  class="ml-20"
                  v-if="subscription_type != 'Monthly' && subscription_type != 'Yearly'"
                >
                  Upgrade to Premium
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline grey lighten-2">
                  {{ upgrade_text }}
                </v-card-title>

                <v-card-text v-if="start_payment">
                  <v-radio-group v-model="radioGroup">
                    <table style="width:100%">
                      <tr>
                        <td>
                          <v-radio
                            key="SE Monathly"
                            label="Monthly Plan (29.99 USD/month)"
                            value="monthly"
                          >
                          </v-radio>
                        </td>
                        <td>
                          <v-radio
                            key="SE Yearly"
                            label="Yearly Plan (289.00 USD/year)"
                            value="yearly"
                          ></v-radio>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <ul class="mt-5" style="margin-left:30%;">
                            <li v-for="feature in monthly_features" v-bind:key="feature">
                                <h6>{{ feature }}</h6>
                            </li>
                          </ul>
                        </td>
                        <!-- <td>
                          <ul class="mt-5">
                            <li v-for="feature in yearly_features" v-bind:key="feature">
                                <h6>{{ feature }}</h6>
                            </li>
                          </ul>
                        </td> -->
                      </tr>
                    </table>
                  </v-radio-group>
                </v-card-text>
                <v-card-text v-else>
                  <div class="logo_div row p-4 mb-4 bg-dark align-items-center">
                    <div class="col-6 px-0">
                      <img style="width: 200px;" :src="logo_url">
                    </div> 
                    <div class="col-6 px-0 text-right">
                      <span class="font-weight-bolder text-light font-size-h4 font-size-h1-lg plan-type">{{plan_type}}</span>
                    </div>
                  </div>
                  <div class="sub_total_div row py-4 mt-4" style="padding:15px;">
                    <div class="col-6">
                      <h4>Sub Total</h4>
                      <h3>Due Now</h3>
                    </div>
                    <div class="col-6 text-right px-0">
                      <h4 class="show-amount">{{sub_total}}</h4>
                      <h3 class="show-amount-span">{{due_now}}</h3>
                    </div>
                  </div>
                  <div class="row" style="padding-left:65px;padding-right:65px;">
                    <div class="col-md-8">
                      <v-text-field
                        v-model="coupon_code"
                        label="Coupon Code"
                        dense
                        outlined
                      ></v-text-field>
                    </div>
                    <div class="col-md-4">
                      <a class="btn btn-success" id="checkCoupon" style="padding: 9px;background-color: #81C784 !important; border-color: #81C784 !important; font-weight: 600;width: 100%;"
                      @click="checkCouponCode()">Apply Coupon Code</a>
                    </div>
                  </div>
                  <stripe-element-card
                    class="ml-20 mr-20"
                    ref="elementRef"
                    :pk="pulishableKey"
                    :hidePostalCode="true"
                    @token="tokenCreated"
                  />
                </v-card-text>
                <h3 class="ml-30" v-if="login_btn && !start_payment">
                  Login again for subscription changes.
                </h3>

                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <button
                    type="button"
                    v-if="start_payment"
                    class="ma-2 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
                  >
                    <span class="v-btn__content" @click="dialog = false">
                      Cancel
                    </span>
                  </button>
                  
                  <v-btn
                    v-if="start_payment"
                    color="green lighten-2"
                    dark
                    @click="selectSubscription"
                  >
                    Select Package
                  </v-btn>

                  <v-btn
                    v-if="!start_payment && !login_btn"
                    @click="closeSubscriptionModal"
                  >
                    Cancel
                  </v-btn>

                  <v-btn
                    v-if="!start_payment && !login_btn"
                    color="green lighten-2"
                    dark
                    @click="submit"
                  >
                    Make Payment
                  </v-btn>

                  <v-btn
                    v-if="!start_payment && login_btn"
                    color="green lighten-2"
                    dark
                    @click="logout_user"
                  >
                    Login again
                  </v-btn>

                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- end:Model -->
        </div>
      </div>
      <div
        v-if="subscription_type == 'Monthly' || subscription_type == 'Yearly'"
        class="card-header"
      >
        <div class="card-title">
          <h3 v-if="payment_status != 'canceled'"
            class="card-label"
          >
            Next renewal on : <b> {{ due_date }}</b>
          </h3>
          <h3 v-if="payment_status == 'canceled'"
            class="card-label"
          >
            Last Date of Subscription : <b> {{ due_date }}</b>
          </h3>
          <div class="my-2" v-if="payment_status != 'canceled' && is_company_admin == 1">
            <button
              @click="cancelSubscription(subscription_id)"
              type="button"
              class="mt-3 ml-20 mr-1 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
            >
              <span class="v-btn__content">
                Cancel Subscription
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <h3 class="card-label">Features Included:</h3>
          <ul>
              <li v-for="feature in features_list" v-bind:key="feature">
                  <h6>{{ feature }}</h6>
              </li>
          </ul>
        </div>
        <!-- </form> -->
      </div>
    </div>
    
    <!--end::Employees-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import EventBus from "@/core/services/store/event-bus";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { LOGOUT } from "@/core/services/store/auth.module";
import { CANCEL_SUBSCRIPTION } from "@/core/services/store/user.module";

export default {
  name: "Subscription",
  vuetify: new Vuetify(),
  components: {
    StripeElementCard,
  },
  data() {
    this.pulishableKey = "pk_test_51ISr2sKH8A8bPL9DaFII2XmGLOlNHDBiqacsIjxcv634CFyBLNV36GHGoqn7wg6iVoOyjmxBMiAdckD5NJeJNVri00ZRW5eXCD";
    return {
      company_type: "Small Enterprise",
      subscription_type: "Free",
      subscription: {},
      subscription_id: "",
      payment_status: "",
      features_list: [],
      due_date: "",
      dialog: false,
      radioGroup: "monthly",
      free_features: [],
      monthly_features: [],
      yearly_features: [],
      start_payment: true,
      login_btn: false,
      upgrade_text: "Upgrade Plans",
      sub_total: "",
      due_now:"",
      plan_type:"",
      logo_url:"",
      loading: false,
      lineItems: [
        {
          price: '', // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      coupon_code: "",
      amount: "",
      couponCodeApplied: "no",
      is_company_admin : 2
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters([
      "getCompany",
      "getFeatures",
      "getSubscriptionType",
      "getSubscription",
      "getSubscriptionId",
      "getPaymentStatus",
    ])
  },
  watch: {
    getCompany: function(newVal) {
      this.company_type = newVal.company_type
        ? newVal.company_type
        : "Small Enterprise";
    },
    getSubscription: function(newVal) {
      this.subscription = newVal;
      this.due_date = newVal.subscription_end_date 
      ? newVal.subscription_end_date 
      : "";
    },
    getSubscriptionId: function(newVal) {
      this.subscription_id = newVal ? newVal : "";
    },
    getPaymentStatus: function(newVal) {
      this.payment_status = newVal ? newVal : "";
    },
  },
  methods: {
    checkCouponCode(){
      let context = this;

      if (context.radioGroup == 'monthly'){
        context.amount = 29.99;
      } else if (context.radioGroup == 'yearly') {
        context.amount = 228.00;
      }

      axios({
        method: "post",
        url: "check-coupon-code",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        data: {
          couponCode: context.coupon_code,
          amount: context.amount
        }
      }).then(
        function(result) {
          if(result.data.status == 1){
            context.couponCodeApplied = 'yes';
            context.due_now = '$'+result.data.result.final_amount+'/+tax';
            Swal.fire("Success", result.data.message, "success");
          }else{
            context.couponCodeApplied = 'no';
            context.due_now = '$'+context.amount+'/+tax';
            Swal.fire("Error", result.data.message, "error");
          }
        },
      );
    },
    cancelSubscription(subscription_id) {
      let context = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to have benefits of premium features!",
        showCancelButton: true,
        confirmButtonText: "Yes"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(CANCEL_SUBSCRIPTION,
           {
              subscription_id: subscription_id
           });
        } else if (result.isDismissed) {
          Swal.fire("Subscription is not cancelled.", "", "info");
        }
      });
    },
    closeSubscriptionModal() {
      let context = this;
      context.subscription_type= "Free";
      this.upgrade_text = "Upgrade Plans";
      context.dialog= false;
      context.start_payment = true;
      context.coupon_code = "";
    },
    logout_user() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    getFeaturesList() {
      let context = this;
      axios({
        method: "get",
        url: "get-features-list",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.free_features) {
            context.free_features = result.data.free_features;
          }
          if (result.data.monthly_features) {
            context.monthly_features = result.data.monthly_features;
          }
          if (result.data.yearly_features) {
            context.yearly_features = result.data.yearly_features;
          }
        },
        function() {
          Swal.fire("Error", "Incomplete Assessment fetch error!", "error");
        }
      );
    },
    selectSubscription() {
      let context = this;
      this.upgrade_text = "";
      context.start_payment = false;
      if(context.company_type == "Small Enterprise") {
        if (context.radioGroup == 'monthly'){
          context.sub_total = '$29.99/Month';
          context.due_now = '$29.99/+tax';
          context.plan_type = 'Monthly Plan';
        } else if (context.radioGroup == 'yearly') {
          context.sub_total = '$228/Year';
          context.due_now = '$228/+tax';
          context.plan_type = 'Yearly Plan';
        }
      } else {
        if (context.radioGroup == 'monthly'){
          context.sub_total = '$29.99/Month';
          context.due_now = '$29.99/+tax';
          context.plan_type = 'Monthly Plan';
        } else if (context.radioGroup == 'yearly') {
          context.sub_total = '$228/Year';
          context.due_now = '$228/+tax';
          context.plan_type = 'Yearly Plan';
        }
      }
    },
    tokenCreated (token) {
      // handle the token
      // send it to your server
      let context = this;
      var code = "";
      if(context.couponCodeApplied == 'yes' ){
        code = context.coupon_code;
      }

      axios({
        method: "post",
        url: "make-payment",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        data: {
          token_id: token.id,
          item_id: context.lineItems[0].price,
          card_id: token.card.id,
          token_obj: token,
          coupon_code: code,
        }
      }).then(
        function(result) {
          if(result.data.status) {
            Swal.fire("Success", result.data.message, "success");
            context.login_btn = true;
          } else {
            Swal.fire("Error", result.data.message, "error");
          }
        },
        function() {
          Swal.fire("Error", "Error in payment please try again!", "error");
        }
      );
    },
    submit () {
      let context = this;
      
      if(context.company_type == "Small Enterprise") {
        if (context.radioGroup == 'monthly'){
          context.lineItems[0].price = process.env.VUE_APP_SE_MONTH_SUBSCRIPTION;
          
        } else if (context.radioGroup == 'yearly') {
          context.lineItems[0].price = process.env.VUE_APP_SE_YEAR_SUBSCRIPTION;
          
        } else {
          Swal.fire("Error", "Please select subscription plan!", "error");
          return;
        }
      } else {
        if (context.radioGroup == 'monthly'){
          context.lineItems[0].price = process.env.VUE_APP_CHAPTER_MONTH_SUBSCRIPTION;
          
        } else if (context.radioGroup == 'yearly') {
          context.lineItems[0].price = process.env.VUE_APP_CHAPTER_YEAR_SUBSCRIPTION;
          
        } else {
          Swal.fire("Error", "Please select subscription plan!", "error");
          return;
        }
      }
      // You will be redirected to Stripe's secure checkout page
      this.$refs.elementRef.submit();
    }
  },
  mounted() {
    let context = this;
    context.company_type = this.getCompany.company_type;

    if(context.company_type == "Small Enterprise") {
        context.logo_url = 'https://askcoda-ui-dev.s3.amazonaws.com/media/logos/logo-se.png';
    }else{
        context.logo_url = 'https://askcoda-ui-dev.s3.amazonaws.com/media/logos/logo-chapter.png';
    }

    // set the tab from previous
    context.$store.dispatch(SET_BREADCRUMB, [{ title: "Subscription" }]);
    this.$store.dispatch(GET_COMPANY);
    EventBus.$on("GET_COMPANY", function() {});
    EventBus.$on("GET_FEATURES", function(newVal) {
        context.features_list = newVal;
    });
    EventBus.$on("GET_SUBSCRIPTION_TYPE", function(newVal) {
        context.subscription_type = newVal;
    });
    EventBus.$on("GET_SUBSCRIPTION", function(newVal) {
        context.subscription = newVal;
    });
    EventBus.$on("GET_SUBSCRIPTION_ID", function(newVal) {
        context.subscription_id = newVal;
    });
    EventBus.$on("GET_PAYMENT_STATUS", function(newVal) {
        context.payment_status = newVal;
    });
    EventBus.$on("GET_IS_COMPANY_ADMIN", function(newVal) {
        context.is_company_admin = newVal;
    });
    EventBus.$on("CANCEL_SUBSCRIPTION", function(newVal) {
        if(newVal.status == 1){
            context.logout_user();
        }
    });

    var domain_url = process.env.VUE_APP_FRONT_URL;
    const contact_us_form = KTUtil.getById("kt_contact_us_form");

context.fv = formValidation(contact_us_form, {});
    context.getFeaturesList();
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },

};
</script>